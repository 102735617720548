import { createGetterSetter } from '../../common/createComputed.js'

const pageProperties = {
  distribution: false,
  distributionPackage: false
}

const items = {}
for (const index in pageProperties) {
  const isInt = pageProperties[index]
  items[index] = createGetterSetter('estimate', index, isInt)
}

export const computed = items
