export default () => ({
  datatypeItems: [
    { id: 'datatype-freehand', name: '手書き原稿' },
    { id: 'datatype-word', name: 'Wordなどのデータ原稿' },
    { id: 'datatype-completed', name: '完全データ原稿（レイアウト完成済）' }
  ],
  bindingItems: [
    { id: 'binding-simple', name: '簡易的な装丁を依頼', value: 'simple' },
    { id: 'binding-standard', name: '本格的な装丁を依頼', value: 'standard' }
  ]
})
