import { createGetterSetter } from '../../common/createComputed.js'

const pageProperties = {
  bookfeature: false,
  progress: false,
  planning: false,
  editing: false,
  writing: false,
  rewriting: false,
  proofreading: false
}

const items = {}
for (const index in pageProperties) {
  const isInt = pageProperties[index]
  items[index] = createGetterSetter('estimate', index, isInt)
}

export const computed = items
