import genreItems from './genreItems.js'
import bookbindingItems from './bookbindingItems.js'

export default () => ({
  genreItems: genreItems,
  bookbindingItems: bookbindingItems,
  circulationItems: [
    100,
    200,
    300,
    400,
    500,
    600,
    800,
    1000,
    1500,
    2000
  ],
  textcolorItems: [
    { id: 'text-mono', name: 'モノクロ', value: 'mono' },
    { id: 'text-color', name: 'カラー', value: 'color' }
  ]
})
