<template>
  <aside id="showphase" class="showphase">
    <transition name="showphase">
      <ul class="showphase_lists" v-show="isShow">
        <li class="showphase_list phase-01_1">基本仕様</li>
        <li class="showphase_list phase-01_2">印刷仕様</li>
        <li class="showphase_list phase-01_3">編集仕様</li>
        <li class="showphase_list phase-01_4">創作の状況</li>
        <li class="showphase_list phase-01_5">販売方法</li>
        <li class="showphase_list phase-01_6">オプション</li>
        <li class="showphase_list phase-02">著者の情報</li>
        <li class="showphase_list phase-03">アンケート</li>
        <li class="showphase_list confirm">確認</li>
        <li class="showphase_list thanks">送信完了</li>
      </ul>
    </transition>
  </aside>
</template>

<script>
export default {
  mounted: function () {
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.onScroll)
  },
  data: () => ({
    isShow: false
  }),
  methods: {
    onScroll () {
      this.updateShow()
    },
    updateShow () {
      const res = window.scrollY > 400
      if (res !== this.isShow) {
        this.isShow = res
      }
    }
  }
}
</script>

<style>
.showphase-enter-active, .showphase-leave-active {
  transition: opacity .3s;
}
.showphase-enter, .showphase-leave-to {
  opacity: 0;
}
</style>
