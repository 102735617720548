<template>
<div class="block-a form__blocks-item">
  <section class="block-a01">
    <header class="form__blocks-header">
      <h2 class="form-block_h2 form__blocks-title"><em>書籍　基本仕様</em></h2>
    </header>
    <section id="block-a01" class="block-a01 form__content">
      <header class="block-01_header form__content-header">
        <h3 class="block-01_header-title form__content-title"><em>1</em>ジャンルの選択</h3>
        <span>必須</span>
      </header>
      <div class="form__content-wrap">
        <div class="block-a01_text form__content-text">
          <p>本の出版には様々な​​ジャンルがあります。<br>
            詩歌、 小説、評論本、エッセイ、ビジネス、自叙伝、などは、テキストを中心としたジャンルです。<br>
            絵本、写真、アート本、などは、写真やイラストや図柄などのビジュアルを中心としたジャンルです。<br>
            <br>
            現在あなたが制作している準備している作品はどのジョンルになりますか？
          </p>
        </div>
        <div class="block-a01_form form__content-form">
          <fieldset>
            <legend>ジャンルを選択してください</legend>
            <ul class="block-01_form form-lists">
              <li class="block-01_form form-list" v-for="(item, index) in genreItems">
                <input type="radio" name="genre" :id="'genreItem_' + index" :value="item.id" v-model="genre" required>
                <label :for="'genreItem_' + index">{{ item.name }}</label>
              </li>
            </ul>
          </fieldset>
        </div>
      </div>
      <aside class="block-a01_aside form__aside none">
      </aside>
    </section>

    <section id="block-a02" class="block-02 form__content">
      <header class="block-02_header form__content-header">
        <h3 class="block-02_header-title form__content-title"><em>2</em>本のサイズ</h3>
        <span>必須</span>
      </header>
      <div class="form__content-wrap">
        <div class="block-02_text form__content-text">
          <table>
            <thead>
              <tr>
                <th width="45">判型</th>
                <th width="75">寸法 mm</th>
                <th>本の種類</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A4判</td>
                <td>210×297</td>
                <td>女性誌など一般的な雑誌サイズ</td>
              </tr>
              <tr>
                <td>B5判</td>
                <td>182×257</td>
                <td>週刊誌サイズ</td>
              </tr>
              <tr>
                <td>A5判</td>
                <td>148×210</td>
                <td>= 菊判 文芸雑誌・教科書サイズ</td>
              </tr>
              <tr>
                <td>B6判</td>
                <td>128×182</td>
                <td>= 四六判 単行本サイズ</td>
              </tr>
            </tbody>
          </table>
          <p><br></p>
        </div>
        <div class="block-02_form form__content-form">
          <fieldset>
            <legend>本のサイズを選んでください</legend>
            <ul class="block-02_form form-lists">
              <li class="block-02_form form-list" v-for="(item, index) in booksizeItems">
                <input type="radio" name="booksize" :id="'booksize_' + index" :value="item.value" v-model="booksize" required>
                <label :for="'booksize_' + index">{{ item.name }}<small>{{ item.desc }}</small></label>
              </li>
            </ul>
          </fieldset>
        </div>
      </div>
      <aside class="block-02_aside form__aside">
        <div class="form__aside-listbox inline">
          <ul class="form__aside-lists">
            <li class="form__aside-list">
              <img src="/img/booksize_turtle.png">
              <span>書籍寸法 亀積み</span>
            </li>
            <li class="form__aside-list">
              <img src="/img/booksize_allsize.png">
              <span>書籍寸法 全部サイズ</span>
            </li>
            <li class="form__aside-list" v-for="item in booksizeItems">
              <img :src="item.image">
              <span>{{ item.name + item.desc }}</span>
            </li>
          </ul>
        </div>
      </aside>
    </section>

    <section id="block-a03" class="block-03 form__content">
      <header class="block-03_header form__content-header">
        <h3 class="block-03_header-title form__content-title"><em>3</em>製本の仕様</h3>
        <span>必須</span>
      </header>
      <div class="form__content-wrap">
        <div class="block-03_text form__content-text">
          <p>製本の仕様には、ハードカバー（上製本）とソフトカバー（並製本）があります。<br>ハードカバーは、表紙を厚紙や革など硬質な素材で作製し高級感や本格感を湛える製本方式です。<br>ソフトカバーは、表紙を本文と同サイズの軟質な用紙で作製し本体価格を抑えられる製本方式です。</p>
        </div>
        <div class="block-03_form form__content-form">
          <fieldset>
            <legend>製本の仕様を選んでください</legend>
            <ul class="block-03_form form-lists">
              <li class="block-03_form form-list" v-for="(item, index) in bookbindingItems">
                <input type="radio" name="bookbinding" :id="'bookbinding_' + index" :value="item.value" v-model="bookbinding" required>
                <label :for="'bookbinding_' + index">{{ item.name }}</label>
              </li>
            </ul>
          </fieldset>
        </div>
      </div>
      <aside class="block-03_aside form__aside none">
      </aside>
    </section>

    <section id="block-a04" class="block-04 form__content">
      <header class="block-04_header form__content-header">
        <h3 class="block-04_header-title form__content-title"><em>4</em>ページ数</h3>
        <span>必須</span>
      </header>
      <div class="form__content-wrap">
        <div class="block-04_text form__content-text">
          <p>原稿から文字組みする際、文字の大きさ、行間の幅などで総ページ数は変化します。文字組みは制作する本の目的や世界観に影響します。<br>
            一般的に、小説やビジネス書の１ページの文字組みは、１ページに15行前後、1列に38文字程度です。
          </p>
          <h5>＜参考書籍＞</h5>
          <table>
            <thead>
              <tr>
                <th width="80">１ページ</th>
                <th width="80">１列</th>
                <th>書籍名</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>15行</td>
                <td>41文字</td>
                <td>生き方</td>
              </tr>
              <tr>
                <td>17行</td>
                <td>40文字</td>
                <td>夢を叶える象</td>
              </tr>
              <tr>
                <td>14行</td>
                <td>38文字</td>
                <td>１％の努力</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="block-04_form form__content-form">
          <fieldset>
            <legend>ページ数を入力ください</legend>
            <div class="block-04_form form-parts">
              <div class="block-04_form form-part">
                <em><input type="number" id="pagecount" name="pagecount" placeholder="300ページまで" v-model="pageCount" :max="maxPageCount" required></em>
                <span>ページ</span><br>
                <span>※絵本は80ページまで</span>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <aside class="block-04_aside form__aside">
        <header class="block-04_aside_header form__aside-header">
          <h3 class="block-04_aside_header-title form__aside-title">ページ数計算</h3>
        </header>
        <div class="block-04_aside_text form__aside-text">
        </div>
        <div class="block-04_aside_form form__aside-form">
          <div class="block-04_form form-parts">
            <div class="block-04_form form-part">
              <span>Word １行の文字数</span>
              <em><input type="text" id="word-wordcount" name="pagecalculation" placeholder="文字数" v-model="charsPerLine"></em>
            </div>
            <div class="block-04_form form-part">
              <span>Word １ページの行数</span>
              <em><input type="text" id="word-linecount" name="pagecalculation" placeholder="行数" v-model="linesPerPage"></em>
            </div>
            <div class="block-04_form form-part">
              <span>Word  総ページ数</span>
              <em><input type="text" id="word-pagecount" name="pagecalculation" placeholder="ページ数" v-model="pages"></em>
            </div>
            <div class="block-04_form form-part">
              <span>挿絵・表図　全体の分量</span>
              <em><input type="text" id="item-percent" name="pagecalculation" placeholder="ページ数" v-model="figurePages"></em>
            </div>
          </div>
          <div class="block-04_aside_form-btm form__aside-button">
                <button type="button" name="pagecalculation" @click="calcPages">
                  <strong>ページ数を計算する</strong>
                </button>
          </div>
          <div class="form__aside-note">※300ページ以上の出版を検討中の方は別途お問い合わせください。</div>
        </div>
      </aside>
    </section>

    <section id="block-05" class="block-05 form__content">
      <header class="block-05_header form__content-header">
        <h3 class="block-05_header-title form__content-title"><em>5</em>制作部数（冊数）</h3>
        <span>必須</span>
      </header>
      <div class="form__content-wrap">
        <div class="block-05_text form__content-text">
          <p>制作部数は、本のもっているポテンシャルにより出版社がご提案するものです。<br>
          しかし、モモンガプレスは、はじめて本の出版にチャレンジしたい、著者になろうとしている方を応援しているインディーズブックプロジェクトです。まずは著者になってもらおう、出版体験をしていただこうというスタンスですので、無理のない部数をご提案しております。<br>
          制作部数は、基本300部。うち著者受取部数を100部とお考えください。
          </p>
        </div>
        <div class="block-05_form form__content-form">
          <fieldset>
            <legend>制作部数（冊数）をお選びください</legend>
            <div class="block-05_form form-parts">
              <div class="block-05_form form-part">
                <select name="circulation" id="circulation" v-model="copies">
                  <option v-for="num in circulationItems" :value="num">{{ num }}部</option>
                </select>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </section>
    <section id="block-06" class="block-06 form__content">
      <aside class="block-06_aside form__aside">
        <header class="block-06_aside_header form__aside-header">
          <h3 class="block-06_aside_header-title form__content-title"><em>6</em>受取部数</h3>
        </header>
        <div class="form__content-wrap">
          <div class="block-06_text form__content-text">
            <p>制作部数を300以上の部数を制作いただくと、受取部数の設定が可能です。（100部単位）
            </p>
          </div>
          <div class="block-06_form form__content-form">
            <fieldset>
              <div class="block-06_form form-parts">
                <div class="block-06_form form-part">
                  <em><input type="text" id="receive" name="receive" placeholder="100部単位で入力" v-model="receiveCopies"></em>
                  <span>部</span>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </aside>
    </section>

    <section id="block-07" :class="['block-07', 'form__content', {unactivated: isBodyColorOnly}]">
      <header class="block-07_header form__content-header">
        <h3 class="block-07_header-title form__content-title"><em>7</em>本文のカラー／モノクロ　選択</h3>
        <span v-if="isBodyColorOnly === false">必須</span>
      </header>
      <div class="form__content-wrap">
        <div class="block-07_text form__content-text">
          <p>テキストを中心とした本を制作する場合、基本「本文がモノクロ」」になります。<br>
          写真やイラストや図柄などのビジュアルを中心とした本を制作する場合、「本文がカラー」になります。<br>
          <br>
          よくわからない場合は、本文モノクロか本文カラーかお選びいただき、後ほどの「ご質問など」項目でお尋ねください。
          </p>
        </div>
        <div class="block-07_form form__content-form">
          <fieldset>
            <legend>本文カラーを指定ください</legend>
            <ul class="block-07_form form-lists">
              <li class="block-07_form form-list" v-for="(item, index) in textcolorItems">
                <input type="radio" name="textcolor" :id="'textcolor_' + index" :value="item.value" :disabled="isBodyColorOnly" v-model="textcolor" required>
                <label :for="'textcolor_' + index">{{ item.name }}</label>
              </li>
            </ul>
          </fieldset>
        </div>
      </div>
      <aside class="block-07_aside form__aside none">
      </aside>
    </section>

    <aside class="block_aside_form block-a_aside_form form__aside-buttom">
      <div class="block-a_aside_form-btm block_form-btm submit">
        <button type="button" name="pagecalculation" @click="next">
          <strong>次に「書籍　印刷仕様」を入力する</strong>
        </button>
      </div>
    </aside>
  </section>
</div>
</template>

<script>
import mixin from './mixin.js'
import data from './bookbasic/data/index.js'
import { computed } from './bookbasic/computed/index.js'
import { calcBodyPages } from '../../model/calcbodypages.js'

export default {
  mixins: [mixin],
  data: data,
  computed: computed,
  methods: {
    calcPages: function () {
      if (
        this.charsPerLine === '' ||
        this.linesPerPage === '' ||
        this.pages === ''
      ) {
        return
      }

      let result = 0
      try {
        result = calcBodyPages(this.charsPerLine, this.linesPerPage, this.pages)
      } catch (e) {
        return
      }

      if (this.figurePages !== '') {
        result += this.figurePages
      }
      this.pageCount = result
    }
  }
}
</script>
