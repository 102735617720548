export default () => ({
  bookcoverItems: [
    { name: 'ブックカバー　あり', value: true },
    { name: 'ブックカバー　なし', value: false }
  ],
  coverbeltItems: [
    { name: 'カバー帯　あり', value: true },
    { name: 'カバー帯　なし', value: false }
  ],
  treatmentItems: [
    { name: 'PP加工　ツヤあり', value: true },
    { name: 'PP加工なし：ツヤなし(ニス加工)', value: false }
  ],
  textcolorItems: [
    { id: 'text-mono', name: 'モノクロ', value: 'mono' },
    { id: 'text-color', name: 'カラー', value: 'color' }
  ],
  mikaeshiItems: [
    { name: '見返し　あり', value: true },
    { name: '見返し　なし', value: false }
  ]
})
