export default [
  { id: 'poem', name: '詩集', isBodyColorOnly: false },
  { id: 'tanka', name: '短歌', isBodyColorOnly: false },
  { id: 'haiku', name: '俳句', isBodyColorOnly: false },
  { id: 'senryu', name: '川柳', isBodyColorOnly: false },
  { id: 'novel', name: '小説', isBodyColorOnly: false },
  { id: 'shortstory', name: '短編小説', isBodyColorOnly: false },
  { id: 'sf-novel', name: 'SF小説', isBodyColorOnly: false },
  { id: 'light-novels', name: 'ライトノベルズ', isBodyColorOnly: false },
  { id: 'history-novel', name: '歴史・時代小説', isBodyColorOnly: false },
  { id: 'twilight', name: 'ミステリー', isBodyColorOnly: false },
  { id: 'economic-novel', name: '経済社会小説', isBodyColorOnly: false },
  { id: 'criticism', name: '評論・文学研究', isBodyColorOnly: false },
  { id: 'essay', name: 'エッセイ・随筆', isBodyColorOnly: false },
  { id: 'business', name: 'ビジネス', isBodyColorOnly: false },
  { id: 'administration', name: '経営学', isBodyColorOnly: false },
  { id: 'autobiography', name: '人物自伝', isBodyColorOnly: false },
  { id: 'management', name: '実践経営', isBodyColorOnly: false },
  { id: 'picture-book', name: '絵本', isBodyColorOnly: true },
  { id: 'photo-album', name: '写真', isBodyColorOnly: true },
  { id: 'art', name: 'アート', isBodyColorOnly: true },
  { id: 'design', name: 'デザイン', isBodyColorOnly: true },
  { id: 'collection', name: '作品集', isBodyColorOnly: true }
]
