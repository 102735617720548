export default {
  form: {
    status: 0,
    reciept: []
  },
  calcPage: {
    charsPerLine: '',
    linesPerPage: '',
    pages: '',
    figurePages: ''
  },
  basic: {
    genre: '',
    booksize: '46-y',
    bookbinding: 'soft',
    pageCount: '',
    copies: 100,
    receiveCopies: '',
    textcolor: 'mono'
  },
  printing: {
    bookcover: false,
    bookcoverPaper: 1,
    coverbelt: false,
    coverbeltPaper: 0,
    frontcoverPaper: 1,
    treatment: false,
    bodyPaper: 1,
    mikaeshi: false,
    mikaeshiPaper: 1
  },
  prepress: {
    bookfeature: '文字メイン',
    progress: '執筆は概ね出来ている',
    planning: '',
    editing: 'simple',
    writing: false,
    rewriting: false,
    proofreading: ''
  },
  creatingstate: {
    datatype: 'Wordなどのデータ原稿',
    binding: 'simple'
  },
  sales: {
    distribution: 'cosignwithsales',
    distributionPackage: 'plan-a'
  },
  option: {
    hakuoshi: false
  }
}
