export default () => ({
  distributionItems: [
    { id: 'distribution-none', name: '流通　なし', value: '' },
    { id: 'distribution-sales-consig', name: '流通　あり（営業+委託配本）', value: 'cosignwithsales' },
    { id: 'distribution-sales', name: '流通　あり（委託配本）', value: 'cosign' },
    { id: 'distribution-consignment', name: '流通　あり（注文のみ）', value: 'bookstore' },
    { id: 'distribution-amazon', name: 'アマゾンのみで販売', value: 'amazon' }
  ],
  distributionPackageItems: [
    { id: 'distribution_package-A', name: '「小規模プロモーション」パッケージ', value: 'plan-a', desc: ['POP作成　※FAXチラシ', 'Amazonページ作成', 'HP掲載　→　ラララShopへリンク', '書店営業（30〜100店舗）'] },
    { id: 'distribution_package-B', name: '「中規模プロモーション」パッケージ', value: 'plan-b', desc: ['POP作成　※FAXチラシ', 'Amazonページ作成', 'HP掲載　→　ラララShopへリンク', '書店営業（〜500店舗）'] },
    { id: 'distribution_package-C', name: '基本パッケージ', value: 'plan-c', desc: ['Amazonページ作成', 'HP掲載　→　ラララShopへリンク', '限定数で書店営業／著者宅付近や職場周り'] }
  ]
})
