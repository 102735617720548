<template>
  <div class="autoprice__confirm-table">

    <!-- 書籍＆著者情報 -->
    <div class="confirm_wrap" >
      <h2 class="autoprice__confirm-tablehead">書籍＆著者情報を確認する</h2>

      <div class="autoprice__confirm-item">
        <label>書籍名</label>
        <em>{{ booktitle }}</em>
      </div>
      <div class="autoprice__confirm-item">
        <label>著者名</label>
        <em>{{ penname }}</em>
      </div>
      <div class="autoprice__confirm-item">
        <label>著者名フリガナ</label>
        <em>{{ pennameRuby }}</em>
      </div>
      <div class="autoprice__confirm-item">
        <label>名前</label>
        <em>{{ namefamily + ' ' +  name}}</em>
      </div>
      <div class="autoprice__confirm-item">
        <label>フリガナ</label>
        <em>{{ kanafamily + ' ' +  kananame}}</em>
      </div>
      <div class="autoprice__confirm-item">
        <label>メールアドレス</label>
        <em>{{ email }}</em>
      </div>
      <div class="autoprice__confirm-item">
        <label>性別</label>
        <em>{{ gender }}</em>
      </div>
      <div class="autoprice__confirm-item">
        <label>年齢</label>
        <em>{{ age }}</em>
      </div>
      <div class="autoprice__confirm-item">
        <label>今後、弊社発信の情報メールをお送りしてもよろしいですか？</label>
        <em>{{ infomail }}</em>
      </div>
      <div class="autoprice__confirm-item">
        <label>ご質問など</label>
        <em style="white-space: pre-wrap;">{{ note }}</em>
      </div>
    </div>
  </div>
</template>

<script>
import formData from '../../store/modules/formstructs/bookdata.js'

const dataKeys = []
for (const type in formData) {
  for (const key in formData[type]) {
    dataKeys.push(key)
  }
}

const computed = {}

Object.assign(computed, Vuex.mapState('bookdata', dataKeys))

export default {
  computed: computed
}
</script>
