import store from './store/index.js'
import Estimate from './components/Estimate.vue'
import Econfirm from './components/Econfirm.vue'
import BookData from './components/BookData.vue'
import Bconfirm from './components/Bconfirm.vue'
import Questionary from './components/Questionary.vue'
import Confirm from './components/Confirm.vue'
import Thanks from './components/Thanks.vue'

Vue.component('estimate', Estimate)
Vue.component('econfirm', Econfirm)
Vue.component('bookdata', BookData)
Vue.component('bconfirm', Bconfirm)
Vue.component('questionary', Questionary)
Vue.component('confirm', Confirm)
Vue.component('thanks', Thanks)

document.addEventListener('DOMContentLoaded', (event) => {
  const app = new Vue({
    el: '#form-block',
    store: store,
    data: {
    },
    computed: {
      formstate: () => {
        return store.getters['common/formstate']
      },
      formStateClass: () => {
        const formstate = store.state.common.status
        if (formstate === 0) {
          const status = store.state.estimate.status
          const phaseLabel = [
            'phase-01_1',
            'phase-01_2',
            'phase-01_3',
            'phase-01_4',
            'phase-01_5',
            'phase-01_6'
          ]
          return phaseLabel[status]
        }

        const formstateLabel = [
          'estimate',
          'econfirm',
          'phase-02',
          'bconfirm',
          'phase-03',
          'confirm',
          'thanks'
        ]

        return formstateLabel[formstate]
      }
    },
    mounted () {
      store.commit('common/setCalculator', estimatelogic)
      store.dispatch('common/fetchMasterPapers')
      store.dispatch('common/fetchMasterTables')

      const meta = document.querySelector('meta[name="environment"]')
      if (meta !== null) {
        const environment = meta.attributes
          .getNamedItem('content')
          .value
        store.commit('common/setEnvironment', environment)
      }
    }
  })
})
